<div id="page">
  <div id="fh5co-container" class="js-fullheight">
    <div class="countdown-wrap js-fullheight">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="display-t js-fullheight">
            <div class="display-tc animate-box">
              <nav role="navigation">
                <div id="fh5co-logo">
                  <a href="javascript:void(0)"
                    ><img style="width: 200px" src="/assets/iwaan/logo-w.png"
                  /></a>
                </div>
              </nav>
              <div class="col-md-12 p-4">
                <p>
                  Iwaan gives powerful SDK and software to help grow business
                  and software.
                </p>
              </div>
              <div class="col-md-12">
                <div class="bord-title-place">
                  <div class="bord-title">SERVICES</div>
                </div>

                <div class="d-flex justify-content-center">
                  <div class="card news-bord">
                    <div class="card-body">
                      <h5 class="card-title">EyeWebBot #1</h5>
                      <div class="card-text">
                        <div class="text-left">
                          <p>
                            EyeWebBot is fast logs-storing and data-storing
                            software in smart and easy ways. It saves your time
                            and the work of development.
                          </p>
                          <a
                            class="more"
                            *ngIf="!readMore"
                            (click)="readMoreAction()"
                            href="javascript:void(0)"
                            >read more</a
                          >
                          <div *ngIf="readMore">
                            <div>
                              <h6>Usage of this product</h6>
                              <ul>
                                <li>
                                  Create forms for your website, Form data is
                                  stored in the EyeWebBot storing system and you
                                  can anywhere you want to access it with your
                                  authentication.
                                </li>
                                <li>
                                  Get visitor details of the website. like IP
                                  address, GEOlocation, visitors device basic
                                  details
                                </li>

                                <li>
                                  You can interact with your website visitors.
                                  For example, you can ask for data in the popup
                                  form for individual visitors.
                                </li>

                                <li>
                                  You can also analyze data of visitors' data
                                  with the EyeWebBot dashboard
                                </li>

                                <li>
                                  EyeWebBot logs program AI stores what are
                                  pages visited on your website an individual
                                  visitors, and which website is referred to
                                  your website that kind of information is
                                  automatically into the EyeWebBot storing
                                  system for your reference.
                                </li>

                                <li>
                                  EyeWebBot also provides a data-storing system
                                  (CMS).
                                </li>
                              </ul>
                              <h6>Security</h6>
                              <p>
                                Iwaan team has a large amount of knowledge of
                                security systems. No one can access your data
                                and information without your knowledge. It is
                                100% safe and secure.
                              </p>

                              <h6>Plans details</h6>

                              <dl>
                                <dt>Free (Without payment details)</dt>
                                <dd>
                                  <ul>
                                    <li>One form with 5 input fields.</li>
                                    <li>
                                      10 counts of create, update, read and
                                      delete operations (CMS).
                                    </li>
                                    <li>10 days Logs storing system.</li>
                                  </ul>
                                </dd>
                                <dt>Basic INR 99/ Monthly</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      Unlimited form with Unlimited input
                                      fields.
                                    </li>
                                    <li>
                                      Infinity counts of create, update, read
                                      and delete operations (CMS).
                                    </li>
                                    <li>Logs storing system.</li>
                                  </ul>
                                </dd>

                                <dt>Premium INR 199/ Monthly</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      Unlimited form with Unlimited input
                                      fields.
                                    </li>
                                    <li>
                                      Infinity counts of create, update, read
                                      and delete operations (CMS).
                                    </li>
                                    <li>Logs storing system plus.</li>
                                    <li>Structural data storing system.</li>
                                  </ul>
                                </dd>

                                <dt>Super Save INR 89/ Monthly</dt>
                                <dd>
                                  <ul>
                                    <li>10 forms with 10 input fields.</li>
                                    <li>
                                      100 counts of create, update, read and
                                      delete operations (CMS).
                                    </li>
                                    <li>Logs storing system plus.</li>
                                    <li>Structural data storing system.</li>
                                  </ul>
                                </dd>
                              </dl>
                              <!-- <h6>Pay fearless policy</h6>
                              <p>
                                If you don't like this product, 7-day money-back
                                guarantee.
                              </p> -->
                              <h6>How to Implement product in your website?</h6>
                              <p>
                                We provide API and SDK to integrate into your
                                applications.
                              </p>
                            </div>
                            <a
                              *ngIf="readMore"
                              class="more"
                              (click)="readMoreAction()"
                              href="javascript:void(0)"
                              >less</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <h1>We Are Coming Soon!</h1> -->
              <!-- <div class="simply-countdown simply-countdown-one"></div> -->

              <div class="row">
                <div class="col-md-12 desc progress-level">
                  <h2 style="margin-bottom: 0px">Progress Level</h2>
                  <div class="d-flex justify-content-center">
                    <div
                      class="progress-circle p{{ progressLevel }}"
                      [ngClass]="{
                        over50: progressLevel > 50
                      }"
                    >
                      <span>{{ progressLevel }}% </span>
                      <div class="left-half-clipper">
                        <div class="first50-bar"></div>
                        <div class="value-bar"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 desc">
                  <h2>
                    We're working hard to finish the development of this site.
                    <br />
                    Register to notify you when it's ready!
                  </h2>
                  <div class="col-md-12 p-4" *ngIf="screen == 'thankyou'">
                    <div class="d-flex justify-content-center">
                      <p class="s-msg">
                        Thank you for subscribing to us. You will get a
                        {{ discount }}% discount for the first buy any service
                        of Iwaan. <br /><small
                          >Use the same email while registering for
                          service</small
                        >
                      </p>
                    </div>
                  </div>
                  <form
                    class="form-inline"
                    id="fh5co-header-subscribe"
                    [formGroup]="subscribeForm"
                    *ngIf="screen == 'form'"
                    (ngSubmit)="subscribe()"
                  >
                    <div class="col-md-12 col-md-offset-0">
                      <div class="form-group" style="display: inline-grid">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Get notify by email"
                          formControlName="email"
                          [ngClass]="{
                            'is-invalid':
                              subscribeForm.controls.email.touched &&
                              subscribeForm.controls.email.errors
                          }"
                        />
                        <div
                          *ngIf="
                            subscribeForm.controls.email.touched &&
                            subscribeForm.controls.email.errors
                          "
                          class="invalid-feedback"
                        >
                          <div
                            *ngIf="
                              subscribeForm.controls.email.errors['required']
                            "
                          >
                            Email is required
                          </div>
                          <div
                            *ngIf="subscribeForm.controls.email.errors['email']"
                          >
                            Email is not valid
                          </div>
                        </div>
                        <button type="submit" class="btn btn-primary">
                          Subscribe
                        </button>
                        <small
                          ><b>Note:</b> Subscribe Now! You will get a
                          {{ discount }}% discount</small
                        >
                      </div>
                    </div>
                  </form>
                  <ul class="fh5co-social-icons">
                    <li>
                      <a [href]="socialMediaLinks?.twitter?.content"
                        ><i class="icon-twitter-with-circle"></i
                      ></a>
                    </li>
                    <li>
                      <a [href]="socialMediaLinks?.linkedin?.content"
                        ><i class="icon-linkedin-with-circle"></i
                      ></a>
                    </li>
                    <li>
                      <a [href]="socialMediaLinks?.instagram?.content"
                        ><i class="icon-instagram-with-circle"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-cover js-fullheight"
      style="
        background-image: url(/assets/images/img_bg_1.jpg);
        object-fit: cover;
      "
    >
      <div class="col-md-12 progress-status">
        <div class="d-flex justify-content-center">
          <img
            style="width: 310px"
            class="logo"
            src="/assets/iwaan/logo-buiding.gif"
          />
        </div>
        <br /><br />
        <div class="d-flex justify-content-center">
          <div class="progress statusBar" style="height: 20px">
            <div
              class="progress-bar"
              role="progressbar"
              attr.style="width: {{ progressLevel }}%"
              attr.aria-valuenow="{{ progressLevel }}"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <h6 class="text-center p-3">{{ progressLevel }}%</h6>
      </div>
    </div>

    <!-- <div class="gototop js-top">
      <a href="#" class="js-gotop"><i class="icon-arrow-up"></i></a>
    </div> -->
  </div>
</div>
