import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginData, User } from '../models/auth.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

export interface userSign {
  isSignedIn: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user: User | undefined;
  private loggedIn = new BehaviorSubject<userSign>({
    isSignedIn: localStorage.getItem('isSignedIn') === 'true',
  });
  get isLoggedIn(): Observable<userSign> {
    return this.loggedIn.asObservable();
  }
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(user: LoginData) {
    this.spinner.show();
    let prem = new Promise((resolve, reject) => {
      if (user.username !== '' && user.password !== '') {
        this.http
          .post<any>(environment.API_URL + 'account/login', user)
          .subscribe(
            (data) => {
              if (data.statusCode == 200) {
                if (Object.keys(data.data).length) {
                  localStorage.setItem('access_token', data.token.access_token);
                  localStorage.setItem('isSignedIn', 'true');
                  localStorage.setItem('userData', JSON.stringify(data));
                  this.loggedIn.next({
                    isSignedIn: true,
                  });
                  location.href = '/';
                  resolve(data);
                }
              } else {
                resolve(data);
              }
              this.spinner.hide();
            },
            (error: any) => {
              console.log(error);
              this.spinner.hide();
              reject(error);
            },
            () => {
              this.spinner.hide();
            }
          );
      }
    });
    return prem;
  }
  getUser() {
    let data: any = localStorage.getItem('userData');
    if (data) {
      data = JSON.parse(data);
      if (data && data.data) {
        data = data.data;
      }
    }
    return data;
  }
  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {}

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {}
  getToken() {
    return localStorage.getItem('access_token');
  }
  logout() {
    this.loggedIn.next({ isSignedIn: false });
    localStorage.removeItem('access_token');
    localStorage.setItem('isSignedIn', 'false');
    localStorage.removeItem('userData');
    location.href = '/account/login';
  }

  httpRequest(req: {
    url: string;
    method: string;
    data?: any;
    options?: any;
    loaderOff?: boolean;
    auth?: boolean;
  }): Promise<any> {
    let prem = new Promise((resolve, reject) => {
      if (!req.loaderOff) this.spinner.show();
      let { url, method, data, options } = req;
      //auth
      options = options ? options : {};
      if (req.auth) {
        let token = this.getToken();
        if (!token) {
          this.logout();
          if (!req.loaderOff) this.spinner.hide();
          reject({ ok: false });
        }
        let head: HttpHeaders = new HttpHeaders({
          Authorization: 'Bearer ' + token,
        });
        options.headers = head;
      }
      if (method == 'post') {
        this.http.post<any>(environment.API_URL + url, data, options).subscribe(
          (res: any) => {
            resolve(res);
          },
          (error: any) => {
            reject(error);
            if (!req.loaderOff) this.spinner.hide();
            if (
              !error.ok &&
              error.error &&
              (error.error.statusCode == 401 || error.error.statusCode == 403)
            ) {
              if (req.auth) this.logout();
            }
          },
          () => {
            if (!req.loaderOff) this.spinner.hide();
          }
        );
      } else if (method == 'get') {
        this.http.get<any>(environment.API_URL + url, options).subscribe(
          (res: any) => {
            resolve(res);
          },
          (error: any) => {
            reject(error);
            if (!req.loaderOff) this.spinner.hide();
            if (
              !error.ok &&
              error.error &&
              (error.error.statusCode == 401 || error.error.statusCode == 403)
            ) {
              if (req.auth) this.logout();
            }
          },
          () => {
            if (!req.loaderOff) this.spinner.hide();
          }
        );
      } else if (method == 'patch') {
        this.http
          .patch<any>(environment.API_URL + url, data, options)
          .subscribe(
            (res: any) => {
              resolve(res);
            },
            (error: any) => {
              reject(error);
              if (!req.loaderOff) this.spinner.hide();
              if (
                !error.ok &&
                error.error &&
                (error.error.statusCode == 401 || error.error.statusCode == 403)
              ) {
                if (req.auth) this.logout();
              }
            },
            () => {
              if (!req.loaderOff) this.spinner.hide();
            }
          );
      }
    });
    return prem;
  }
  httpAuthFileDownload(url: string, filePath: string) {
    this.httpRequest({
      url: url,
      method: 'get',
      options: { responseType: 'blob' as 'json', observe: 'response' },
      auth: true,
    })
      .then((data) => {
        if (data.body && data.body.size > 0) {
          var filename = filePath.split('/').pop();
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data.body);
          link.download = filename ? filename : '';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.log('File is not found');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  showSuccess(message: string, title?: string) {
    this.toastr.success(message, title);
  }
  showError(message: string, title?: string) {
    this.toastr.error(message, title);
  }
  showWarning(message: string, title?: string) {
    this.toastr.warning(message, title);
  }
}
