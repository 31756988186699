import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../core/servies/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: [
    './under-construction.component.css',
    './style.css',
    './animate.css',
    './icomoon.css',
    './css-circular-prog-bar.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UnderConstructionComponent implements OnInit {
  progressLevel: number = 40;
  socialMediaLinks: any = {};
  discount: number = 5;
  subscribeForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  screen = 'form';
  public readMore: boolean = false;
  constructor(private authService: AuthService) {}
  ngOnInit(): void {
    let req: any = {
      groupNames: ['social-media-links', 'progress-level', 'CD-VALUE'],
      responseType: 'object',
    };
    this.authService
      .httpRequest({
        url: 'cms/get',
        method: 'post',
        data: req,
        loaderOff: false,
        auth: false,
      })
      .then((data) => {
        if (data.statusCode == 200) {
          console.log(data);
          this.socialMediaLinks = data.data['social-media-links'];
          this.progressLevel =
            data.data['progress-level'] && data.data['progress-level']['iwaan']
              ? Number(data.data['progress-level']['iwaan'].content)
              : 0;
          this.discount =
            data.data['CD-VALUE'] && data.data['CD-VALUE']['DISCOUNT']
              ? Number(data.data['CD-VALUE']['DISCOUNT'].content)
              : 0;
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  subscribe() {
    if (this.subscribeForm.valid) {
      let sendData = this.subscribeForm.value;
      this.authService
        .httpRequest({
          url: 'subscribe',
          method: 'post',
          data: sendData,
          loaderOff: false,
          auth: false,
        })
        .then((data) => {
          if (data.statusCode == 200) {
            //console.log(data);
            this.screen = 'thankyou';
          } else {
            //show error
            this.authService.showError(data.message[0]);
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  }
  readMoreAction() {
    this.readMore = !this.readMore;
  }
}
